// Spaces , special characters are not allowed in the name and must start with alphabet
function validateAccountLogicalName(name: string) {
    if (!name) {
        return false;
    }
    const regex = /^[a-zA-Z][a-zA-Z0-9_]{0,62}[a-zA-Z0-9]$/;
    return regex.test(name);
}

export default validateAccountLogicalName;
