import { ApDataGrid } from '@uipath/portal-shell-react';
import React from 'react';

import useTenantTagsLabelsViewModel from './TenantTagsLabelsViewModel';

const TenantTagsLabelsComponent: React.FC = () => {

    const { apGridRef } = useTenantTagsLabelsViewModel();

    return (
        <div
            style={{ width: '100%' }}
            data-cy='tenant-tags-labels-grid'>
            <ApDataGrid
                data-cy="ap-data-grid"
                ref={apGridRef}
            />
        </div>
    );
};

export default TenantTagsLabelsComponent;
