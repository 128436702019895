export const MaxUsernameLength = 256;
export const MaxFirstNameLength = 64;
export const MaxLastNameLength = 64;
export const ReservedUsernames = [ 'ServiceAccount_08da82db-70b7-4' ];

export enum UserType {
    User,
    Robot,
    DirectoryUser,
    DirectoryGroup,
    RobotAccount,
}
