import type {
    ILabelModel,
    ILabelModelObject,
    ILabelModelPaginationResult,
    IPagination,
    ITagSearchFiltersDto,
    IValueModel,
    IValueModelObject,
    IValueModelPaginatedResult,
} from '@experiences/interfaces';

export const DEFAULT_SEARCH_FILTERS = {
    skip: 0,
    take: 20,
};

export const cleanLabelTagResponse = (response: ILabelModelPaginationResult) => {
    // Need to add id for ap-data-grid to work
    const mappedObjects: ILabelModelObject[] = [];
    response.value.forEach((labelObj: ILabelModel) => {
        mappedObjects.push({
            id: labelObj.key,
            ...labelObj,
        });
    });
    return {
        results: mappedObjects,
        totalCount: response.count,
    };
};

export const cleanValueTagResponse = (response: IValueModelPaginatedResult) => {
    // Need to add id for ap-data-grid to work
    const mappedObjects: IValueModelObject[] = [];
    response.value.forEach((valueObj: IValueModel) => {
        mappedObjects.push({
            id: valueObj.key,
            ...valueObj,
        });
    });
    return {
        results: mappedObjects,
        totalCount: response.count,
    };
};

export const mapObjectId = (response: ILabelModel) =>
    // Need to add id for ap-data-grid to work
    ({
        id: response.key,
        ...response,
    })
;

export const mapPagination = (pagination: IPagination): Partial<ITagSearchFiltersDto> => ({
    startsWith: pagination.searchTerm,
    skip: pagination.skip,
    take: pagination.top,
    orderByDirection: pagination.sortOrder,
});
