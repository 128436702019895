export enum DirectoryEntityType {
    user = 0,
    group = 1,
}

export type ResolveDirectoryEntityType =
  | 'DirectoryUser'
  | 'DirectoryGroup'
  | 'LocalUser'
  | 'LocalGroup'
  | 'RobotAccount';

export interface IDirectoryEntry {
    domain?: string;
    source: string;
    identifier: string;
    identityName: string;
    displayName: string;
    email?: string;
    type: DirectoryEntityType;
    objectType?: ResolveDirectoryEntityType;
    chipType?: string;
    optionId?: string;
}
